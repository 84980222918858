@import "../../sass/default/color_variable";

.cs-social_btns {
  display: flex;
  flex-wrap: wrap;
  margin: -15px;

  a {
    height: 48px;
    width: 48px;
    margin: 15px;
    border-radius: 7px;
  }

  svg {
    width: 22px;
    height: 22px;
  }
}

.cs-footer_menu {
  list-style: none;
  padding: 0;
  margin: 0;
  flex-wrap: wrap;

  a {
    margin: 0px 15px;
    display: inline-block;
  }
}

@media screen and (max-width: 991px) {
  .cs-social_btns {
    margin: -5px;
  }

  .cs-social_btns a {
    height: 40px;
    width: 40px;
    margin: 5px;
  }

  .cs-footer_menu a {
    margin: 0px 8px;
  }
}

.pointer-events-none {
  pointer-events: none;
  cursor: default;
}